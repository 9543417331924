import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Carousel from '../components/carousel'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default ({ data }) => {
  const galleryImages = [
    { img: data.i1, caption: 'My Block, Denver, Colorado' },
    { img: data.i2, caption: 'Shafer & Grace, Richmond, Virginia' },
    { img: data.i3, caption: 'Anthem House, Baltimore, Maryland' },
    { img: data.i4, caption: 'Moxy Hotel, Denver, Colorado' },
    { img: data.i5, caption: 'Hilton Garden Inn, Suffolk, Virginia' },
    { img: data.i6, caption: 'TownePlace Suites & Courtyard by Marriott, Grapevine, Texas' },
    { img: data.i7, caption: 'Atlanta College of Arts Student Housing, Atlanta, Georgia' },
    { img: data.i8, caption: 'Georgia Tech Family Housing, Atlanta, Georgia' },
    { img: data.i9, caption: 'University of Kansas, Lawrence, Kansas' },
  ];

  // Dynamically update year January 1st at midnight.
  const yearsActive = new Date().getFullYear() - new Date(1986, 11, 31).getFullYear();

  return (<Layout>
    <SEO title="Infinity Structural System" keywords={[]}/>

    <div className='container container--home'>
      <h1 className='title title--index type-center color-primary'>
        The Infinity Structural System
      </h1>

      <h2 className='subtitle subtitle--index type-center'>Infinity Structures
        is a structural framing system supplier specializing in the Epicore MSR
        Composite Floor System on pre-panelized load-bearing metal stud walls.</h2>

      <Img fluid={data.iAnatomy.childImageSharp.fluid} className='margin-sm margin-y anatomy-image'/>

      <div className='grid'>
        <div className='grid__column grid__column--12 grid__column--4--md grid__column--center'>
        </div>

        <div className='grid__column grid__column--12 grid__column--8--md'>
          <p>We can generally do 9 or 10 stories of Infinity System, which could start on the
            slab-on-grade or an elevated concrete Transfer Slab. Our height limitation is based on how
            many stories of Infinity System we have, so you could have 10 stories of Infinity System
            sitting on a 5th Floor Transfer Slab which would actually be a 15 Story building!</p>
        </div>

        <div className='grid__column grid__column--12 grid__column--4--md grid__column--center type-center'>
          {/* TODO: Extract to css module? */}
          <div
            className='card card--outlined card--primary color-primary type-center type-bold'
            style={{ borderWidth: '9px', margin: 'auto', width: '220px' }}
          >
            <div className='card__content'>
              <p className='margin-nulled margin-y' style={{ fontSize: '1.4rem' }}>Since 1986</p>
              <p className='margin-nulled' style={{ fontSize: '5rem', lineHeight: '5.4rem' }}>{yearsActive}</p>
              <p style={{ fontSize: '1.4rem' }}>YEARS OF EXCELLENCE</p>
            </div>

          </div>
        </div>

        <div className='grid__column grid__column--12 grid__column--8--md'>
          <p>We developed The Infinity Structural System in 1986, and a review of our Installation
            Lists (available upon request) proves that we have by far the most extensive experience
            and expertise of anyone in the business. For over 30 years and hundreds of projects, we
            have “fine tuned” the Infinity System making constant improvements for a more economical,
            faster, trouble-free and user-friendly system. Our scope of work typically provides
            the complete structural framing package ready for your local concrete sub to place
            the wire mesh, rebar, and concrete. Infinity Structures provides Project Management
            and Engineering assistance, and has several highly trained and experienced erection
            crews. Depending upon the Client’s desired approach, our Engineering Involvement
            varies from providing Light Gage Engineering Design/calculations/shop drawings to
            full EOR Structural Engineering design from the foundations through the roof.</p>
        </div>


        <div className='grid__column grid__column--12 grid__column--4--md grid__column--center'>
          <Img style={{ maxWidth: '272px', margin: 'auto' }} fluid={data.iFloorSystem.childImageSharp.fluid}/>
          <p className='type-center margin-nulled'>EPICORE MSR Composite Floor System</p>
        </div>

        <div className='grid__column grid__column--12 grid__column--8--md'>
          <p>The Infinity Structural System consists of the Epicore MSR Composite Floor
            System on pre-panelized load-bearing metal stud walls. Epicore MSR is a 2” deep
            high performance long-span composite metal deck that acts as a permanent form
            as well as the positive reinforcing in the slab. The dovetail rib with embossed
            locking lugs, flat bottom profile, and closed flutes make it uniquely suited for
            load-bearing metal studs since it inherently distributes loads evenly over the metal
            studs (eliminating the need for expensive load-distribution members or attempting to
            align joists and studs). The Epicore MSR Floor System can clear span up to almost 30
            feet. The field poured slab is between a 4” and 8” slab thickness utilizing 4,000
            psi regular weight concrete (3,000 psi or lightweight may be used if desired).</p>
        </div>

        <div className='grid__column grid__column--12 grid__column--4--md grid__column--center'>
          <Img style={{ maxWidth: '216px', margin: 'auto' }} fluid={data.iShearPanel.childImageSharp.fluid}/>
          <p className='type-center margin-nulled'>Infinity Shear Panel</p>
        </div>

        <div className='grid__column grid__column--12 grid__column--8--md'>
          <p>The load-bearing metal stud wall panels are typically 6” galvanized studs spaced
            at 16” on center (other stud sizes & spacing are available if desired). The gage of
            the studs will be 18, 16, 14, or 12 Gage depending upon axial loads, wind loads and
            stud height. The metal studs are custom ordered for your particular project, therefore
            the metal studs may be cut to any length to achieve your desired floor-to-floor
            heights (i.e. there are no standard stud height constraints). Infinity’s wall
            panels are fabricated off-site in a controlled environment using welded connections
            under very stringent QC requirements to ensure the highest quality available.</p>
          <p>The lateral loads (wind and seismic) may be handled utilizing our exclusive Infinity
            Shear Panel (ISP), or traditional methods such as concrete shear walls, reinforced
            CMU/masonry shear walls, structural steel braced frames, or a combination.</p>
        </div>
      </div>

      <h1 className='title title--xxxl type-center color-primary'>Applications</h1>
      <h3 className='subtitle subtitle--index type-center'>
        The Infinity System is ideal for mid-to-high-rise residential projects such as
        Apartments, Condos, Lofts, Student Housing, Hotels and Senior Living Facilities.
      </h3>

      <div className='gallery--applications'>
        <Carousel content={galleryImages} carWidth={960} carHeight={540} />
      </div>
    </div>
  </Layout>);
}

export const pageQuery = graphql`
    query {
        iAnatomy: file(relativePath: { eq: "index/anatomy.png" }) {
            childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        iShearPanel: file(relativePath: { eq: "index/Infinity_Shear_Panel.jpg" }) {
            childImageSharp {
#                fixed(width: 216, height: 294) {
                fluid(maxWidth: 216) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        iFloorSystem: file(relativePath: { eq: "index/EPICORE_MSR_Composite_Floor_System.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 216) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        i1: file(relativePath: { eq: "index/gallery/01-MyBlo060_(42942).png" }) { ...HomeCarouselImageFragment },
        i2: file(relativePath: { eq: "index/gallery/02-Sha_and_G030_(42984).png" }) { ...HomeCarouselImageFragment },
        i3: file(relativePath: { eq: "index/gallery/03-AntHo100_(43354).jpg" }) { ...HomeCarouselImageFragment },
        i4: file(relativePath: { eq: "index/gallery/04-MoxyH040_(43308).jpg" }) { ...HomeCarouselImageFragment },
        i5: file(relativePath: { eq: "index/gallery/05-HGIVA020_(40645).jpg" }) { ...HomeCarouselImageFragment },
        i6: file(relativePath: { eq: "index/gallery/06-TPS_and_C020_(43003).png" }) { ...HomeCarouselImageFragment },
        i7: file(relativePath: { eq: "index/gallery/07-AtCol030_(34350).png" }) { ...HomeCarouselImageFragment },
        i8: file(relativePath: { eq: "index/gallery/08-GTech010_(40639).png" }) { ...HomeCarouselImageFragment },
        i9: file(relativePath: { eq: "index/gallery/09-UniKS050_(43301).jpg" }) { ...HomeCarouselImageFragment }
    }
`

